import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import HeaderComponent, {
	headerTransform,
} from "./../../../../components/header";
import FooterComponent, {
	footerTransform,
} from "./../../../../components/footer";
import Banner, {
	bannerTransform,
} from "./../../../../components/university/Banner";
import NavigationFooterPaid from "../../../../components/NavigationFooterPaid";
import {
	NavigationHeaderGeneric,
	NavigationFooter,
	headerTransformRebrand,
	footerTransformRebrand,
	Context,
} from "@website-builder/ui/shared";

import {
	MOBILE_L_BP,
	addToDataLayer,
	getCookie,
	getEssentialData,
	hbsptEventHandler,
} from "@website-builder/utilities-index";
import { setCustomPadding, isAddMargin } from "../../helpers";
import {
	triggerVWOSegmentEvents,
	triggerAppFinishedSegmentEvents,
} from "@website-builder/utilities-index";
import Sections, { sectionTransform } from "./../../../sections";
import Components, { ComponentTransform } from "./../../../../components";

import {
	UniversityWrapper,
	LeftComponentWrapper,
	RightComponentWrapper,
	BodyWrapper,
} from "./style";
import { QueryClientProvider } from "react-query";
import { queryClient } from "@website-builder/utilities-index";

import {
	B2U_REBRAND_CONTENT_TYPES,
	B2U_REBRAND_PHASED_ROLLOUT_VWO_COOKIE,
} from "@website-builder/ui/b2u";
import { useMediaQuery } from "@react-hook/media-query";

const UniversityLayout = ({
	story,
	basicConfig = {},
	token,
	storyList = undefined,
	globalVariableWithURL = [],
	mentorProfilesData = [],
	formData = {},
}) => {
	const segmentProps = {
		typeFormLink: story?.content?.typeFormLink,
		courseSlug: story?.content?.courseSlug,
	};
	let preHerobannerPadding = [72, 72];
	// You can add data that are set as page properties .This will be passed as context to all the components
	let context = getEssentialData(story);
	let header = null;
	let footer = null;
	let rightSection = [];
	let leftSection = [];
	let banner = null;
	let body = [];
	let isRebrandPage = false;
	// VWO's traffic split cookie used for phased rollout of UP Rebrand
	let isRebrandFlow = false;
	let isRebrandHeader = false;
	let isRebrandFooter = false;
	let isPaidRebrandFooter = false;

	if (token) {
		context.token = token;
	}

	useEffect(() => {
		isRebrandFlow = getCookie(B2U_REBRAND_PHASED_ROLLOUT_VWO_COOKIE) === "2";

		const eventListenerCallback = (event) => {
			hbsptEventHandler(event, {
				is_rebrand_page: isRebrandPage,
				is_rebrand_flow: isRebrandFlow,
				workshop_id: story?.content?.workshopId,
				triggerHubspotCustomEvent: story?.content?.triggerHubspotCustomEvent,
			});
		};

		window.addEventListener("message", eventListenerCallback);
		triggerVWOSegmentEvents();
		triggerAppFinishedSegmentEvents({ ...segmentProps });
		pushAnalyticsData();

		return () => {
			window.removeEventListener("message", eventListenerCallback);
		};
	}, [isRebrandPage]);

	// checks for mobile width
	const satisfiesMobileWidth = useMediaQuery(`(max-width: ${MOBILE_L_BP}px)`);
	const [isMobile, setIsMobile] = useState(satisfiesMobileWidth);
	useEffect(() => {
		setIsMobile(satisfiesMobileWidth);
	}, [satisfiesMobileWidth]);

	const pushAnalyticsData = () => {
		addToDataLayer({
			page_workshop_id: story.content.workshopId,
			page_full_slug: story.full_slug,
			is_rebrand_page: isRebrandPage,
			is_rebrand_flow: isRebrandFlow,
		});
	};

	try {
		//  For stories that has only navigation(header and footer) we need to get only header and footer
		let headerInput = null;
		let footerInput = null;
		if (
			story.content.component &&
			story.content.component.toLowerCase().indexOf("layout") !== -1
		) {
			headerInput = story.content.header[0];
			footerInput = story.content.footer[0];
		} else {
			headerInput = story.content.layout.content.header[0];
			footerInput = story.content.layout.content.footer[0];
			leftSection = story.content.leftSection;
			rightSection = story.content.rightSection;
			body = story.content?.body;
			isRebrandPage = B2U_REBRAND_CONTENT_TYPES.includes(
				story.content.component,
			);
			Array.isArray(story?.content?.banner) &&
				story?.content?.banner[0] &&
				(banner = bannerTransform(story?.content?.banner[0], { basicConfig }));
		}
		const {
			isHeaderSticky = false,
			isHeaderLight = false,
			navHeaderCta: headerCTA = [],
			stickyHeader = [],
		} = story.content;
		if (story.content?.stickyHeader?.[0]) {
			preHerobannerPadding[1] += 60;
		}
		if (!headerInput?.hidePreNav) {
			preHerobannerPadding[0] += 32;
		}
		if (headerInput) {
			isRebrandHeader = headerInput?.component === "navigationHeaderGeneric";
			header = isRebrandHeader
				? headerTransformRebrand({
						...headerInput,
						stickyHeader: stickyHeader?.[0],
						isHeaderSticky,
						isHeaderLight,
						headerCTA,
					})
				: headerTransform(
						headerInput,
						globalVariableWithURL,
						story.content?.stickyHeader?.[0],
					);
		}
		if (footerInput) {
			isRebrandFooter = footerInput?.component === "navigationFooter";
			isPaidRebrandFooter = footerInput?.component === "navigationFooterPaid";
			if (isRebrandFooter) {
				footer = footerTransformRebrand(footerInput);
			} else if (isPaidRebrandFooter) {
				footer = footerInput;
			} else {
				footer = footerTransform(footerInput);
			}
		}
	} catch (error) {
		console.error(error);
	}

	/* Loads sections(like fullwidth section) here*/
	const constructSection = (sections, ComponentWrapper, parentSection) => {
		return sections.map(
			({ component: sectionName, ...sectionComponents }, index) => {
				const Section = Sections[sectionName];
				let sectionProps = {};
				if (sectionTransform[sectionName]) {
					sectionProps = sectionTransform[sectionName](sectionComponents);
				}
				const name = sectionComponents?.components?.[0]?.component || "";
				if (Section && sectionName !== "TwoColumnSection") {
					const pageSection = sectionProps.pageSection;
					return (
						<Section
							key={`${sectionName}-${index}`}
							context={context}
							className={isAddMargin(name, parentSection) ? "add-margin" : ""}
							{...sectionProps}
						>
							{constructComponents(
								sectionComponents,
								ComponentWrapper,
								parentSection,
								sectionName,
								pageSection,
							)}
						</Section>
					);
				} else if (sectionName === "TwoColumnSection") {
					const filterComponentsByDeviceVisibilty = ({
						hideOnDevices = [],
					}) => {
						if (hideOnDevices.includes("mobile") && isMobile) return false;
						if (hideOnDevices.includes("desktop") && !isMobile) return false;
						return true;
					};
					const leftColumn = sectionComponents?.leftSection
						?.filter?.(filterComponentsByDeviceVisibilty)
						?.map?.((component) => {
							return constructComponents(
								component,
								ComponentWrapper,
								parentSection,
								sectionName,
							);
						});
					const rightColumn = sectionComponents?.rightSection
						?.filter?.(filterComponentsByDeviceVisibilty)
						?.map?.((component) => {
							return constructComponents(
								component,
								ComponentWrapper,
								parentSection,
								sectionName,
							);
						});
					return (
						<Section
							key={`${sectionName}-${index}`}
							context={context}
							{...sectionProps}
							leftSection={leftColumn}
							rightSection={rightColumn}
							leftHashLink={sectionComponents?.leftSection?.[0]?.hashLink}
							rightHashLink={sectionComponents?.rightSection?.[0]?.hashLink}
						/>
					);
				} else {
					console.error(`${sectionName} is not difined in the Blocks`);
					new Error(`${sectionName} is not difined in the Blocks `);
				}
			},
		);
	};

	const checkPadding = (Component) => {
		if (
			Component === "Accordion" ||
			Component === "UpcomingPrograms" ||
			Component === "QuickLinksPanel" ||
			Component === "BlogCategories" ||
			Component === "Tuition"
		)
			return false;
		else return true;
	};

	/* Loads components(can be anything that comes inside component */
	const constructComponents = (
		{ components },
		ComponentWrapper,
		parentSection,
		sectionName,
		pageSection,
	) => {
		return components.map(({ component: name, ...props }, index) => {
			const Component = Components[name];
			let componentProps = {};
			if (ComponentTransform[name]) {
				componentProps = ComponentTransform[name](props, {
					story: story.content,
					storyList,
					context,
					pageSection,
					basicConfig,
					globalVariableWithURL,
					mentorProfilesData,
					formData,
				});
			} else {
				componentProps = props;
			}
			if (Component) {
				return (
					// addPadding will change when actual implementation happens
					<ComponentWrapper
						hide={componentProps.hideElementInSmallScreen}
						addPadding={checkPadding(name)}
						addMargin={isAddMargin(name, parentSection)}
						padding={setCustomPadding(name, parentSection)}
						key={`${name}-${index}`}
						className={parentSection}
					>
						<Component
							context={context}
							parentSection={parentSection}
							sectionName={sectionName}
							pageSection={pageSection}
							{...componentProps}
						></Component>
					</ComponentWrapper>
				);
			} else {
				console.error(`${name} is not difined in the Components`);
				new Error(`${name} is not difined in the Components `);
			}
		});
	};

	const sharedData = {
		context: context,
		formData: formData,
	};

	return (
		<QueryClientProvider client={queryClient}>
			<Context.Provider value={sharedData}>
				<UniversityWrapper preHeroBannerPadding={preHerobannerPadding}>
					{isRebrandHeader ? (
						<NavigationHeaderGeneric
							{...header}
							context={context}
							basicConfig={basicConfig}
						/>
					) : (
						<HeaderComponent
							Link={Link}
							{...header}
							context={context}
							basicConfig={basicConfig}
						></HeaderComponent>
					)}
					<main className="main-wrapper">
						{!isRebrandPage ? (
							<section className="banner">
								{banner && (
									<Banner
										{...banner}
										context={context}
										basicConfig={basicConfig}
									></Banner>
								)}
							</section>
						) : null}
						<div
							className={`section-container ${
								isRebrandPage ? "rebrand-section" : ""
							}`}
						>
							{leftSection?.length || rightSection?.length ? (
								<>
									<section className="left-section">
										{constructSection(
											leftSection,
											LeftComponentWrapper,
											"left",
										)}
									</section>
									<section className="right-section">
										{constructSection(
											rightSection,
											RightComponentWrapper,
											"right",
										)}
									</section>
								</>
							) : null}
							{body?.length ? (
								<section className="body-section">
									{constructSection(body, BodyWrapper, "body")}
								</section>
							) : null}
						</div>
					</main>
					{isRebrandFooter ? (
						<NavigationFooter
							className="rebrand-footer"
							{...footer}
							context={context}
							basicConfig={basicConfig}
						/>
					) : null}
					{isPaidRebrandFooter ? (
						<NavigationFooterPaid
							className="paid-rebrand-footer"
							{...footer}
							context={context}
							basicConfig={basicConfig}
						/>
					) : null}
					{!(isRebrandFooter || isPaidRebrandFooter) ? (
						<FooterComponent
							Link={Link}
							{...footer}
							context={context}
							basicConfig={basicConfig}
						/>
					) : null}
				</UniversityWrapper>
			</Context.Provider>
		</QueryClientProvider>
	);
};

export default UniversityLayout;
